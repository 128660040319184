<template>
  <div class="app-container">
    <el-card class="filter-container" shadow="never">
      <div>
        <i class="el-icon-search"></i>
        <span>筛选搜索</span>
        <el-button style="float: right" @click="getList()" type="primary" size="small">
          查询结果
        </el-button>
        <el-button style="float: right; margin-right: 15px" @click="handleResetSearch()" size="small">
          重置
        </el-button>
      </div>
      <div style="margin-top: 15px">
        <el-form :inline="true" :model="listQuery" size="small" label-width="140px">
          <el-form-item label="Start Date:">
            <el-date-picker v-model="listQuery.startDate" type="datetime" placeholder="Start Date"
              value-format="yyyy-MM-dd HH:mm:SS"></el-date-picker>
          </el-form-item>
          <el-form-item label="Order#: ">
            <el-input v-model="listQuery.orderSn" placeholder="Order Sn">
            </el-input>
          </el-form-item>
          <el-form-item label="Type: ">
            <el-select v-model="listQuery.purchaseType" placeholder="Purchase Type">
              <el-option v-for="item in purchaseTypeOptions" :key="item" :value="item" :label="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Merchant: ">
            <el-select v-model="listQuery.merchantId" placeholder="Select Merchant" collapse-tags>
              <div class="el-input">
                <input type="text" class="el-input__inner" v-model="merchantSearchValue" @keyup="
                  handleFilter(
                    merchantOptions,
                    'merchantOptionsFiltered',
                    merchantSearchValue,
                  )
                " />
              </div>
              <el-option v-for="item in merchantOptionsFiltered" :key="item.id" :value="item.id" :label="item.name">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Order Status: ">
            <el-select v-model="listQuery.orderStatus" placeholder="Order Status">
              <el-option v-for="item in Object.keys(OrderStatusDict)" :key="item" :value="item" :label="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Payment Status: ">
            <el-select v-model="listQuery.paymentStatus" placeholder="Payment Status">
              <el-option v-for="item in paymentStatusOptions" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Payment ID:">
            <el-input v-model="listQuery.stripePaymentRecordId" placeholder="Payment ID">
            </el-input>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
    <div class="table-container">
      <el-table ref="orderTable" :data="list" style="width: 100%" @selection-change="handleSelectionChange"
        v-loading="listLoading" border>
        <el-table-column type="selection" width="60" align="center"></el-table-column>
        <el-table-column label="Order No." width="100" align="center">
          <template slot-scope="scope">{{ scope.row.orderNumber }}</template>
        </el-table-column>
        <el-table-column label="User Nickname" align="center">
          <template slot-scope="scope">{{
            scope.row.consumer.nickname
          }}</template>
        </el-table-column>
        <el-table-column label="User Email" align="center">
          <template slot-scope="scope">{{ scope.row.consumer.email }}</template>
        </el-table-column>
        <el-table-column label="Order Status" width="110" align="center">
          <template slot-scope="scope">
            <p>
              {{
                convertOrderStatus(
                  scope.row.orderStatus,
                scope.row.purchaseType,
                scope.row.bulkStatus
                            )
              }}
              <el-button icon="el-icon-edit" type="primary" size="mini"
                @click="handleEditStatus(scope.row)"></el-button>
            </p>
          </template>
        </el-table-column>
        <el-table-column label="Amount" align="center">
          <template slot-scope="scope">{{
            scope.row.fees.total.amount
          }}</template>
        </el-table-column>
        <el-table-column label="Bulk Status" width="200" align="center">
          <template slot-scope="scope">
            {{ scope.row.bulkStatus }}
            <p>
              <el-button v-if="scope.row.bulkStatus == 'In progress'" size="mini" type="danger"
                @click="enableBulk(scope.row.bulk.id)">
                Force Enable
              </el-button>
            </p>
          </template>
        </el-table-column>
        <el-table-column label="Merchant" align="center">
          <template slot-scope="scope">{{
            scope.row.bulkStatus != "Direct"
              ? scope.row.lineItems[0].merchantName
              : ""
          }}</template>
        </el-table-column>
        <el-table-column label="Products" align="center">
          <template slot-scope="scope">
            <div v-for="item in scope.row.lineItems" v-bind:key="item.id">
              <p>
                {{ item.quantity + "*" + item.productName }}
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Captain" align="center">
          <template slot-scope="scope">{{
            scope.row.bulkStatus != "Direct"
              ? scope.row.captainName == null
                ? "NULL"
                : scope.row.captainName
              : ""
          }}</template>
        </el-table-column>
        <el-table-column label="Payment Status" align="center">
          <template slot-scope="scope">
            <p>
              {{ scope.row.paymentStatus }}
              <el-button icon="el-icon-edit" type="primary" size="mini"
                @click="handleEditPayment(scope.row)"></el-button>
            </p>
            <p>
              {{ scope.row.stripePaymentRecordId }}
            </p>
          </template>
        </el-table-column>
        <el-table-column label="Ordered At" width="190" align="center">
          <template slot-scope="scope">{{
            new Date(scope.row.orderedAt).toString()
          }}</template>
        </el-table-column>
        <el-table-column label="Est. Delivery" width="190" align="center">
          <template slot-scope="scope">
            {{
              scope.row.estimateDeliveryTime == null
                ? ""
                : new Date(scope.row.estimateDeliveryTime).toString()
            }}
            <el-button icon="el-icon-edit" type="primary" size="mini"
              @click="handleEditEstDelivery(scope.row)"></el-button>
          </template>
        </el-table-column>
        <el-table-column label="Operations" width="160" align="center">
          <template slot-scope="scope">
            <p>
              <el-button size="mini" @click="handleShowOrderDetail(scope.$index, scope.row)">Details
              </el-button>
            </p>
            <p>
              <el-button size="mini" type="warning" v-if="scope.row.orderStatus == 'Created'"
                @click="handleShowDeliverOrder(scope.$index, scope.row)">Deliver
              </el-button>
            </p>
            <p>
              <el-button size="mini" type="danger" v-if="scope.row.orderStatus != 'Cancelled'"
                @click="handleShowCloseOrder(scope.$index, scope.row)">Close
              </el-button>
            </p>
            <p>
              <el-button size="mini" type="primary" @click="sendNotification(scope.row)">Send Notification
              </el-button>
            </p>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="batch-operate-container">
      <el-button style="margin-left: 20px" class="search-button" @click="handleGenerateReceipt()" type="primary"
        size="small">
        生成Receipt
      </el-button>
    </div>
    <el-dialog title="Close Order" :visible.sync="closeVisible" width="30%">
      <el-input v-model="closeMsg" placeholder="Note of Closing Order"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeVisible = false">Cancel</el-button>
        <el-button type="primary" @click="handleCloseOrder()">Close</el-button>
      </span>
    </el-dialog>
    <el-dialog title="Deliver Order" :visible.sync="deliverVisible" width="30%">
      <el-form ref="deliverForm" :model="deliverInfo" label-width="150px">
        <el-form-item label="Delivery Company" prop="company">
          <el-input v-model="deliverInfo.deliveryCompany" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="Tracking Sn" prop="sn">
          <el-input v-model="deliverInfo.deliverySn" auto-complete="off"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deliverVisible = false">Cancel</el-button>
        <el-button type="primary" @click="handleDeliverOrder()">Deliver</el-button>
      </span>
    </el-dialog>
    <el-dialog title="Send Notification" :visible.sync="notificationVisible" width="30%">
      <el-form ref="notificationForm" :model="notificationParam" label-width="150px">
        <el-form-item label="Title(English)" prop="titleEn">
        <el-input
          v-model="notificationParam.titleEn"
          auto-complete="off"
        ></el-input>
      </el-form-item>
        <el-form-item label="Title(简体中文)" prop="titleZh">
        <el-input
          v-model="notificationParam.titleZh"
          auto-complete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="Body(English)" prop="bodyEn">
        <el-input
          v-model="notificationParam.bodyEn"
          auto-complete="off"
          :rows="3"
        ></el-input>
      </el-form-item>
      <el-form-item label="Body(简体中文)" prop="bodyZh">
        <el-input
          v-model="notificationParam.bodyZh"
          auto-complete="off"
          :rows="3"
        ></el-input>
      </el-form-item>
        <el-form-item label="Deep Link">
          <el-checkbox v-model="notificationOrderDetail"></el-checkbox>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="notificationVisible = false">Cancel</el-button>
        <el-button type="primary" @click="submitSendNotification()">Send</el-button>
      </span>
    </el-dialog>
    <el-dialog title="Update Payment Status" :visible.sync="updatePaymentVisible" width="30%">
      <el-form ref="paymentForm" :model="updatePaymentParam" label-width="150px">
        <el-form-item label="Order# : " prop="id">
          {{ updatePaymentOrderSn }}
        </el-form-item>
        <el-form-item label="Status: " prop="status">
          <el-select v-model="updatePaymentParam.status">
            <el-option v-for="item in paymentStatusOptions" :key="item" :name="item" :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="handleSubmitEditPayment">Submit</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="Update Order Status" :visible.sync="updateStatusVisible" width="30%">
      <el-form ref="orderStatusForm" :model="updateStatusParam" label-width="150px">
        <el-form-item label="Order# : " prop="id">
          {{ updateStatusOrderSn }}
        </el-form-item>
        <el-form-item label="Status: " prop="status">
          <el-select v-model="updateStatusParam.status">
            <el-option v-for="item in changeOrderStatusOptions" :key="item.value" :label="item.name"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="handleSubmitEditStatus">Submit</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="Update Estimate Delivery" :visible.sync="updateEstDeliveryVisible" width="30%">
      <el-form ref="estDeliveryForm" :model="updateEstDeliveryParam" label-width="150px">
        <el-form-item label="Order# : " prop="id">
          {{ updateEstDeliveryOrderSn }}
        </el-form-item>
        <el-form-item label="Est. Delivery Time: " prop="estimateDeliveryTime">
          <el-date-picker v-model="updateEstDeliveryParam.estimateDeliveryTime" type="datetime"
            placeholder="Est. Delivery"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="handleSubmitEstDelivery">Submit</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <div class="pagination-container">
      <el-pagination background @current-change="handleCurrentChange" layout="total, sizes,prev, pager, next,jumper"
        :page-size="listQuery.count" :current-page.sync="listQuery.page" :total="total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {
  fetchList as fetchOrderList,
  closeOrder,
  deliverOrder,
  updatePayment,
  updateStatus,
  updateEstDelivery,
} from '@/api/order';
import { forceEnable as forceEnableBulk } from '@/api/bulk';
import { fetchList as fetchMerchantList } from '@/api/merchant';
import { sendToConsumer } from '@/api/notification';
import { OrderStatusDict } from './orderStatus';

const paymentStatusOptions = [
  'PRE_AUTHED',
  'CAPTURED',
  'CANCELLED',
  'REFUNDED',
];
const purchaseTypeOptions = ['BULKING', 'DIRECT_PURCHASE'];
const paymentStatusDict = {
  'Pre-authed': 'PRE_AUTHED',
  Captured: 'CAPTURED',
  Cancelled: 'CANCELLED',
  Refunded: 'REFUNDED',
  'Mismatched Amount': 'MISMATCH_AMOUNT',
};
const defaultListQuery = {
  page: 1,
  count: 20,
  startDate: null,
  merchantId: null,
  orderSn: null,
  purchaseType: null,
  orderStatus: null,
  paymentStatus: null,
  stripePaymentRecordId: null,
};
export default {
  name: 'orderList',
  data() {
    return {
      listLoading: true,
      list: null,
      closeOrderId: null,
      closeVisible: false,
      deliverVisible: false,
      closeMsg: null,
      deliverInfo: {
        orderId: null,
        deliveryCompany: null,
        deliverySn: null,
      },
      queryParam: {
        startDate: null,
      },
      updatePaymentParam: {
        id: null,
        status: null,
      },
      updateStatusParam: {
        id: null,
        status: null,
      },
      updateEstDeliveryParam: {
        id: null,
        estimateDeliveryTime: null,
      },

      updatePaymentOrderSn: null,
      updateStatusOrderSn: null,
      updateEstDeliveryOrderSn: null,
      updatePaymentVisible: false,
      updateStatusVisible: false,
      updateEstDeliveryVisible: false,
      paymentStatusOptions,
      orderStatusOptions: [],
      changeOrderStatusOptions: [],
      purchaseTypeOptions,
      listQuery: { ...defaultListQuery },
      total: null,

      merchantOptions: [],
      merchantOptionsFiltered: null,
      merchantSearchValue: null,

      selectedOrders: [],

      notificationVisible: false,
      notificationOrder: null,
      notificationOrderDetail: true,
      notificationParam: {
        id: null,
        titleEn: null,
        titleZh: null,
        bodyEn: null,
        bodyZh: null,
        apnType: 'single',
      },
      OrderStatusDict,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.listLoading = true;
      const param = JSON.parse(JSON.stringify(this.listQuery));
      param.page -= 1;
      fetchOrderList(param).then((response) => {
        this.listLoading = false;
        this.list = response.data.data;
        this.total = response.data.recordsTotal;
      });
      fetchMerchantList().then((response) => {
        this.merchantOptions = response.data.data;
        this.merchantOptions.sort((a, b) => a.name.localeCompare(b.name));
        this.merchantOptions.unshift({
          id: null,
          name: '--No Selected Merchant--',
        });
        this.merchantOptionsFiltered = this.merchantOptions;
      });
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleShowOrderDetail(index, row) {
      this.$router.push({
        path: '/orderService/orderDetail',
        query: { id: row.id },
      });
    },
    handleUpdateOrder(index, row) {
      this.$router.push({
        path: '/orderService/updateOrder',
        query: { id: row.id },
      });
    },
    handleShowCloseOrder(index, row) {
      this.closeVisible = true;
      this.closeOrderId = row.id;
    },
    handleCloseOrder() {
      this.$confirm('Close this Order?', 'Warning', {
        confirmButtonText: 'Close',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }).then(() => {
        closeOrder([{ orderId: this.closeOrderId, note: this.closeMsg }]).then(
          (response) => {
            this.$message({
              message: 'Successfully Closed',
              type: 'success',
              duration: 1000,
            });
            this.closeVisible = false;
            this.getList();
          },
        );
      });
    },
    handleShowDeliverOrder(index, row) {
      this.deliverInfo.orderId = row.id;
      this.deliverVisible = true;
    },
    handleDeliverOrder() {
      this.$confirm('Deliver this Order?', 'Warning', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }).then(() => {
        deliverOrder([this.deliverInfo]).then((response) => {
          this.$message({
            message: 'Successfully Delivered',
            type: 'success',
            duration: 1000,
          });
          this.deliverVisible = false;
          this.getList();
        });
      });
    },
    enableBulk(bulkId) {
      this.$confirm('Force enable the bulk of this order?', 'Warning', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }).then(() => {
        forceEnableBulk(bulkId).then(() => {
          this.$message({
            message: 'Success',
            type: 'success',
            duration: 1000,
          });
          this.getList();
        });
      });
    },
    handleEditPayment(order) {
      this.updatePaymentParam.id = order.id;
      this.updatePaymentOrderSn = order.orderNumber;
      this.updatePaymentParam.status = paymentStatusDict[order.paymentStatus];
      this.updatePaymentVisible = true;
    },
    handleEditStatus(order) {
      const orderStatus = order.orderStatus.toUpperCase();
      this.updateStatusParam.id = order.id;
      this.updateStatusOrderSn = order.orderNumber;
      this.updateStatusParam.status = order.orderStatus.toUpperCase();
      this.changeOrderStatusOptions = [];
      let hasCurrent = false;
      for (const status in OrderStatusDict) {
        const option = OrderStatusDict[status][order.purchaseType].option();
        if (option != null) {
          this.changeOrderStatusOptions.push(option);
          if (option.value == orderStatus) hasCurrent = true;
        }
      }
      if (!hasCurrent) {
        this.changeOrderStatusOptions.push({
          name: this.convertOrderStatus(
            orderStatus,
            order.purchaseType,
            order.bulkStatus,
          ),
          value: orderStatus,
        });
      }
      this.updateStatusVisible = true;
    },
    handleEditEstDelivery(order) {
      this.updateEstDeliveryOrderSn = order.orderNumber;
      this.updateEstDeliveryParam.id = order.id;
      this.updateEstDeliveryParam.estimateDeliveryTime = null;
      this.updateEstDeliveryVisible = true;
    },
    sendNotification(order) {
      this.notificationParam.id = order.consumer.id;
      this.notificationOrder = order;
      this.notificationVisible = true;
    },
    submitSendNotification() {
      const notificationParam = { ...this.notificationParam };
      if (this.notificationOrderDetail) {
        const deeplinkParam = {
          path: '/link/v1/orderDetail',
          params: {
            orderId: this.notificationOrder.id,
          },
        };
        notificationParam.deeplinkParam = deeplinkParam;
      }
      notificationParam.id = this.notificationOrder.consumer.id;
      this.$confirm('Send Notification?', 'Warning', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }).then(() => {
        sendToConsumer(notificationParam).then(() => {
          this.$message({
            message: 'Success',
            type: 'success',
            duration: 1000,
          });
        });
        this.notificationVisible = false;
      });
    },
    handleSubmitEditPayment() {
      this.updatePaymentVisible = false;
      updatePayment(this.updatePaymentParam).then(() => {
        this.$message({
          message: 'Success',
          type: 'success',
          duration: 1000,
        });
        this.getList();
      });
    },
    handleSubmitEditStatus() {
      this.updateStatusVisible = false;
      updateStatus(this.updateStatusParam).then(() => {
        this.$message({
          message: 'Success',
          type: 'success',
          duration: 1000,
        });
        this.getList();
      });
    },
    handleSubmitEstDelivery() {
      this.updateEstDeliveryVisible = false;
      updateEstDelivery(this.updateEstDeliveryParam).then(() => {
        this.$message({
          message: 'Success',
          type: 'success',
          duration: 1000,
        });
        this.getList();
      });
    },
    convertOrderStatus(orderStatus, purchaseType, bulkStatus) {
      return OrderStatusDict[orderStatus.toUpperCase()][purchaseType].display(
        bulkStatus,
      );
    },
    camelToDisplay(text) {
      const result = text.replace(/([A-Z])/g, ' $1');
      const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      return finalResult;
    },
    handleSelectionChange(newValue) {
      this.selectedOrders = newValue;
    },
    handleResetSearch() {
      this.listQuery = { ...defaultListQuery };
    },
    handleGenerateReceipt() {
      if (this.selectedOrders.length == 0) {
        this.$message({
          message: '请选择订单',
          type: 'warning',
          duration: 1000,
        });
        return;
      }
      const consumerId = this.selectedOrders[0].consumer.id;
      const ids = [];
      for (const order of this.selectedOrders) {
        if (order.consumer.id != consumerId) {
          this.$message({
            message: '请选择同一用户的订单',
            type: 'warning',
            duration: 1000,
          });
          return;
        }
        ids.push(order.id);
      }
      const routeData = this.$router.resolve({
        path: '/receipt',
        query: { ids },
      });
      window.open(routeData.href, '_blank');
    },
    handleFilter(src, dst, keyword) {
      if (keyword == null || keyword == '') return;
      const matched = src.filter((x) => x.name.toLowerCase().includes(keyword.toLowerCase()));
      const unmatched = src.filter(
        (x) => !x.name.toLowerCase().includes(keyword.toLowerCase()),
      );
      this[dst] = matched.concat(unmatched);
    },
  },
};
</script>
